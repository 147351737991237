@import "../../assets/scss/variables";

.auth-wrapper {
  background-image: url("../../assets/images/bg.png");
  background-size: cover;
  background-position: center;
}

.login-container {
  background-color: #fff;
  .user-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      padding: 9px 0;
      cursor: pointer;
      font-family: $font-medium !important;
      box-shadow: 2px 3px 5px #00000026;
      color: $text-color;
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }
  .first-container {
    position: relative;
    z-index: 1;
    .login-background-image {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      z-index: -20;
    }
  }
  .second-container {
    height: calc(100% - 170px);
    z-index: 22;

    .login-form {
      align-items: center;
      label {
        align-self: flex-start;
      }
      .form-title {
        text-align: center;
      }
    }
  }
}

.auth-wrapper {
  min-height: calc(100vh - 100px);
  padding: 25px 0px;
}

.ant-alert {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

@media only screen and (max-width: 991px) {
  .auth-wrapper {
    padding: 25px 5px;
  }
}
