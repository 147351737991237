$primary: #2a8fff;
$secondary: #171c3c;
$white: #ffffff;
$black: #000000;
$text-color: #2d2f44;
$blue: #2a8fff;
$green: #7ed957;
$purple: #6c5984;
$light-purple: #cb6ce6;
$brown: #f8b041;
$border-color: rgba(0, 0, 0, 0.05);
$theme-border: solid 1px
  rgba(
    $color: $primary,
    $alpha: 0.2,
  );
$theme-shadow: 0 0 0 1px
  rgba(
    $color: $primary,
    $alpha: 0.4,
  );
//font families
$font-bold: "Gordita-Bold";
$font-light: "Gordita-Light";
$font-medium: "Gordita-Regular";
$font-subtitle: "Gordita-Medium";

//font-sizes
$font-size-x-lg: 40px;
$font-size-30: 30px;
$font-size-lg: 20px;
$font-size-md: 14px;
$font-size-sm: 12px;
$font-size-x-sm: 10px;
