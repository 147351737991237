.dash-formContainer {
    width: 100%;
    height: auto;
    background: url("../../../assets/images/auth/auth-back.jpg");
    background-size: 100% 100%;


    & .form-sections {
        width: 100%;

        & .form-types {
            display: flex;
            justify-content: center;
            width: max-content;
            padding: 0 26px;
            & h5 {
                padding: 10px 0 0 6px;
                color: $text-color;
                font-size: $font-size-lg;
            }
        }
        & .form-rows {
            width: 100%;
            display: flex;
            margin: 12px 0;
            align-items: center;
            flex-wrap: wrap;
            padding: 0 40px;

            & .form-padding {
                padding: 0px 10px;
            }
        }
        // .center-x {
        //     justify-content: center;
        // }

        & .update-form {
            width: 100%;
            display: flex;
            padding: 0 38px;
            // justify-content: center;
            
        }
    }
}

