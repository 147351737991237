@import "../../assets/scss/variables";

.pricing-container {
  padding: 40px 90px;
  position: relative;

  .bg {
    background-image: url("../../assets/images/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: calc(100vh - 64px);
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
  }

  .heading-section {
    padding: 60px 0;

    & p {
      color: $text-color;
      font-size: $font-size-x-lg;
      font-family: $font-medium;
    }
  }

  .pricing-main {
    margin: auto;
    display: flex;
    color: $text-color;
    font-size: $font-size-md;

    & .color-blue {
      color: $blue;
      font-size: $font-size-lg;
    }

    & .pricing-section {
      height: 70vh;

      width: 60%;
      height: 100%;

      & .pricing-row {
        display: flex;
        height: 100%;

        & .pricing-card {
          width: 100%;
          border-left: 1px solid $border-color;
          min-height: 500px;
          height: 100%;

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: 1px solid $border-color;
          }

          & .pricing-header {
            width: 100%;

            & .top-items-head {
              height: 50px;
              text-align: center;
              width: 100%;
            }

            // .customize {
            // padding: 0;
            // margin: 0;
            a {
              color: $blue;
              font-size: $font-size-lg;
              text-decoration: none;
            }
            // }
          }

          & .pricing-items {
            & .pricings {
              text-align: center;
              min-height: 45px;
              vertical-align: middle;

              & .check-icon {
                color: $green;
                font-size: 25px;
              }
            }

            & .pricing-typs {
              min-height: 45px;
              vertical-align: middle;
            }
          }
        }
      }
    }

    & .align-center {
      display: grid;
      place-items: center;
    }

    & .pricing-image {
      width: 40%;
      padding: 0 20px;

      & .image-section {
        display: flex;
        justify-content: center;
        & img {
          width: 100%;
          max-width: 360px;
          min-width: 290px;
          max-height: 500px;
          object-fit: cover;
          border-radius: 15px;
        }
      }
    }
  }

  .pricing-details {
    width: 100%;
    display: flex;
    padding-top: 200px;

    .video-section {
      // width: 50%;
      // height: 0;
      // padding-bottom: 40%;
      // position: relative;
      width: 100%;
      max-width: 850px;
      height: 0;
      padding-bottom: 40%;
      position: relative;

      .box {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        video {
          width: calc(100%);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .buy-premuim {
      // width: 50%;
      // padding-top: 80px;
      // padding-left: 60px;
      flex-grow: 1;
      // background: red;
      padding-top: 2%;
      // padding-top: 80px;
      // padding-left: 60px;

      .desc {
        // font-size: $font-size-x-lg;
        // max-width: 650px;
        // line-height: 60px;
        // margin: 0 0 30px;
        font-size: 2.2vw;
        max-width: 650px;
        line-height: 3.5vw;
        margin: 0 0 30px;

        span {
          color: $blue;
          // font-size: $font-size-x-lg;
          font-size: 2.2vw;
        }
      }
      .premium-heading {
        font-size: $font-size-lg;
      }
      .points {
        margin: 0;
        margin-bottom: 2px;
        font-size: $font-size-sm;
      }
      .btn-sec {
        display: flex;
        align-items: center;
        margin: 20px 0 0 0;

        p {
          font-size: $font-size-sm;
          margin: 0 0 0 10px;
        }
      }
      .direct-message {
        font-size: $font-size-x-sm;
      }
    }
  }
  .interview {
    display: flex;
    align-items: center;
    padding-top: 200px;

    .interview-details {
      width: 40%;

      .coming-soon {
        font-size: $font-size-x-lg;
        color: $blue;
        text-transform: uppercase;
      }
      .make-interview {
        font-size: $font-size-lg;
      }
    }

    .interview-image {
      width: 60%;

      img {
        width: 100%;
        max-width: 1100px;
      }
    }
    p {
      max-width: 500px;
    }
    .points {
      display: flex;
      align-items: center;
      margin: 15px 0;

      .checks {
        width: 27px;
        height: 27px;
        border-radius: 89px;
        background-color: $green;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
        min-width: 27px;
        min-height: 27px;
      }
      p {
        margin: 0 0 0 10px;
        max-width: unset;
      }
    }
  }
}
