@import "../../assets/scss/variables";

.signup-container {
  .first-container {
    img {
      &.signup-icon {
        width: 70px;
      }

      &.logo {
        position: relative;
        top: 30px;
        width: 500px;
      }

      width: 100px;
    }
    .inner-container {
      h3 span {
        font-family: $font-light !important;
      }
    }
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.with-form {
    width: 1020px;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px !important;
    height: 720px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 16px #00000026;

    .first-container {
      width: 380px !important;
      background: transparent url("../../assets/images/auth/sign-up-back.png") 0% 0% no-repeat padding-box;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.role-container {
        width: 200px;
        height: 100%;
      }

      &.bg-2 {
        background: transparent url("../../assets/images/auth/signup-ban-2.jpg") 0% 0% no-repeat padding-box;
        background-size: 100% 100%;
      }

      &.role-select {
        background: transparent url("../../assets/images/auth/sign-up-main-back.png") 0% 0% no-repeat padding-box;
        background-size: 100% 100%;
        padding: 40px 15px;
        justify-content: flex-start;
      }

      .logo {
        position: absolute;
        top: 60px;
        width: 200px;
      }

      h3 {
        // text-decoration: underline;
        // text-underline-position: under;

        span {
          font-size: $font-size-md;
          color: $white;
          font-family: $font-medium !important;
          border-bottom: 1px solid $white;
          padding-bottom: 6px;
        }
      }

      .inner-container {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        flex-direction: column;
        padding: 50px 25px 0px 25px;

        .box {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin: 5px 0px 0px 0px;

          &:first-of-type {
            margin-top: 24px;
          }

          h3 {
            font-size: $font-size-md;
            text-decoration: none;
            color: $white;
            margin-bottom: 3px;
          }

          p {
            font-size: $font-size-x-sm;
            color: $white;
            margin: 0px 0 20px 0 !important;
            font-family: $font-light !important;
          }

          img {
            width: 50px;
          }

          span {
            padding: 0px 0px 0px 8px;
          }
        }
      }
    }

    .second-container {
      max-width: 740px;
      width: 100%;
      padding: 0 50px;
      max-width: inherit;

      &.signup-option {
        align-items: center;
      }
      .c-button {
        align-self: flex-end;
      }
      .c-input {
        width: 300px;
      }
    }

    &.role-container {
      max-width: 1024px;
      height: 720px;

      .second-container {
        height: 500px;
        justify-content: center;
      }
    }
  }
  .ant-picker-input > input:placeholder-shown {
    text-overflow: ellipsis;
    border: none !important;
  }
  .c-input .ant-picker {
    box-shadow: none !important;
    border: 1px solid $primary;
  }
  .react-tel-input {
    .c-input > input {
      background-color: transparent !important;
    }
  }
}

.form-actions {
  display: flex;
  button:last-of-type {
    margin-left: 1em;
  }
}

.confirm-email-card {
  align-self: center;
  height: 400px;
  display: flex;
  width: 800px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 520px;
    height: 280px;

    .mail-icon {
      width: 60px;
    }

    button {
      text-decoration: underline;
    }
  }
}
