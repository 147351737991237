.c-footer {
  position: relative;
  .inner-container {
    display: flex;
    justify-content: space-between;
    padding: 40px 50px 30px 50px;
    background-color: $white;
    z-index: 22;
    position: relative;
    ul {
      display: flex;
      flex-direction: column;
      padding: 0px;
      margin: 30px 10px 0px 0px;
      min-width: 150px;
      &.info {
        width: 400px;
        margin: 0px 50px 0px 0px;
        justify-content: space-between;
      }
      .logo {
        max-width: 300px;
        width: 100%;
      }
      h3 {
        font-family: $font-bold !important;
        font-size: $font-size-sm;
        text-decoration: underline;
        text-underline-position: under;
        &.green {
          color: $green;
        }
        &.blue {
          color: $blue;
        }
        &.red {
          color: $primary;
        }
      }
      a,
      p {
        color: $secondary;
        font-size: $font-size-sm;
        margin: 0px 0px 5px 0px;
      }
    }
    .links-wrapper {
      width: 600px;
      justify-content: space-between;
      margin: 0px 0px 0px 50px;
      display: flex;
    }
  }
  .shadow-box {
    background: transparent radial-gradient(closest-side at 50% 68%, #4b4b55 -133%, #ffffff00 200%) -22% 0 no-repeat padding-box;
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 300px;
    top: -50px;
  }
  .footer-bottom {
    text-align: center;
    font-size: $font-size-sm;
    padding-bottom: 12px;
    a {
      font-size: $font-size-sm;
      color: $primary;
    }
  }
}
