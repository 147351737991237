@import "../../assets/scss/variables";

.jobs-page {
  position: relative;
  overflow: hidden;
  .header-wrapper {
    height: 110px;
    padding: 0px 50px 0px 50px;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(52, 30, 91, 1) 0%, rgba(245, 38, 102, 1) 100%);

    .header {
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      padding: 0px 5px;

      .form-fields {
        display: flex;
        align-items: center;

        .c-input {
          margin: 0px;

          &:first-of-type {
            margin: 0px 30px 0px 0px;
          }
        }
      }

      .filters {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        .filter-icon {
          width: 32px;
        }
      }

      .statistics-wrapper {
        display: flex;
        align-items: center;

        .statistic {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          padding: 0px 10px;
          height: 85px;

          h4 {
            color: $white;
            margin: 5px;
          }

          p {
            color: $white;
            font-size: $font-size-sm;
            font-family: $font-light;
            text-align: center;
            line-height: 11px;
            margin: 0px 0px 15px 0px;
            min-width: 100px;
            max-width: 105px;
          }

          .statistic-icon {
            width: 20px;
          }

          &:first-of-type {
            p {
              max-width: 140px;
            }
          }
        }
      }
    }
  }
  .jobs-wrapper {
    display: flex;
    padding: 0px;

    .jobs-list {
      padding: 15px 30px;
      // padding: 15px;
      height: calc(100vh - 124px);
      overflow-y: scroll;
    }

    .job-details {
      overflow: hidden;
      flex: 1;
      height: calc(100vh - 64px);
      overflow-y: auto;
      // background: #efefef;

      .ant-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    .job-filter-section {
      display: flex;
      align-items: center;
      // height: 60px;
      justify-content: space-around;
      width: 100%;
      padding: 30px 36px 10px 32px;
      input {
        width: 100%;
      }
      .c-input {
        width: 100%;
        margin: 0 8px;

        &:first-child {
          margin: 0 8px 0 0;
        }

        .ant-input-prefix {
          margin: 0;
        }
      }
      .filter-icon {
        width: 32px;
      }
    }
    .ant-input-prefix {
      margin-right: 0;
    }
  }
  .ant-input-affix-wrapper {
    border: none !important;
  }
}
