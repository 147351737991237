body {
  margin: 0;
  color: $text-color;
  // position: fixed;
  overflow: auto;
}

html * {
  font-family: $font-medium;
}

.b-text {
  font-family: $font-bold;
}

button {
  border: none;
  background: transparent;
  outline: none;
}

p,
a,
span,
li,
h1 {
  font-size: $font-size-md;
  color: $text-color;
  font-family: $font-medium;
}

.color-text {
  color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $secondary;
}

mark {
  background: transparent;
  font-family: $font-bold;
  color: $primary;
  padding: 0px;

  &.blue {
    color: $blue;
  }
}

a {
  text-decoration: none;
}

img {
  cursor: pointer;
}

.preloader {
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.5);
  padding-top: 6em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-container {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  padding: 50px 0px 50px 0px;

  .first-container {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .second-container {
    max-width: 650px;
  }

  img {
    max-width: 900px;
  }
}

.c-container-centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  margin: 100px 0px;
  position: relative;

  &.pl {
    padding: 0px 0px 0px 150px;
  }

  &.small {
    min-height: 100px;
  }

  &.medium {
    min-height: 200px;
  }

  img {
    width: 600px;
    // &.right-image {
    //   // border-radius: 25px;
    //   // &.lg-rad {
    //   //   // border-radius: 60px;
    //   // }
  }

  // &.lg {
  //   // width: 750px;
  // }
}

p {
  margin-top: 12px;
  // font-family: $font-medium !important;
  font-family: $font-medium;
}

.img-back-cover {
  height: 100%;
  position: absolute;
  left: -500%;
  right: 752px;
  top: 0px;
  background: $brown;

  &.right {
    right: -500%;
    left: 535px;
    background-color: $brown;
  }

  &.purple {
    background: $purple;
  }
}

// custom input styling

.ant-form-item-control {
  width: 100%;
}

.c-input {
  position: relative;

  .ant-picker {
    box-shadow: $theme-shadow !important;
  }

  input {
    font-size: $font-size-sm;
    // box-shadow: $theme-shadow !important;
    // border: none !important;
    border-radius: 100px !important;
    height: 32px !important;
    padding: 0 18px !important;
    // box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
    border: 1px solid $primary !important;
    background: $white !important;

    &.ant-input-lg {
      width: 400px;
    }

    &.ant-input-sm {
      width: 300px;
    }

    &.xs {
      width: 150px;
    }

    &:hover {
      border: none;
      border-right-width: 0px !important;
    }

    &:focus {
      border-color: $primary;
    }
  }

  // textarea {
  //   box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) !important;
  //   // border: solid 1px rgba($color: $primary, $alpha: 0.3);
  //   border: none !important;
  //   border-radius: 10px !important;
  //   padding: 1em !important;
  //   font-size: $font-size-sm;
  // }

  &.c-input-with-icon {
    .ant-form-item-control-input-content {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;

      .input-icon {
        position: absolute;
        left: 12px;
        z-index: 1;
        max-width: 17px;
      }

      input {
        padding: 0 15px 0 35px !important;
      }
    }
  }

  .ant-form-item-explain-error {
    div {
      font-size: 12px;
      margin-left: 1em;
    }
  }
  .ant-form-item-extra {
    margin-left: 0.5em;
  }
}

// antd select

.select-w100 {
  .ant-select {
    width: 100% !important;
  }
}

.c-text-box {
  width: 100%;

  .c-text-area {
    width: 100%;
    resize: none;
    height: 200px !important;
    border-radius: 20px;
    // border: $theme-border !important;
    border: 1px solid $primary !important;
    padding: 20px;
  }

  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none !important;
  }
}

.ant-input-password {
  border: none !important;
  width: auto;

  .ant-input-suffix {
    position: absolute;
    right: 10px;
    top: 10px;
    // display: none;
  }
}

.ant-form-item-label {
  label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: " ";
  }
  label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    content: "*";
  }
}

.ant-form-item-control-input-content {
  display: flex;
  flex-direction: column;
  button.c-button.default {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    span {
      color: #000000d9;
    }
  }
}

.ant-input-affix-wrapper {
  padding: 0px;
  border-radius: 40px;

  &:hover {
    border: none;
  }

  &:focus {
    border: none;
    box-shadow: none;
    // box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) !important;
  }
}

// custom ant checkbox
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $primary;
    border-color: $white;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary;
}

.ant-form-item-with-help {
  max-height: 100px;
}

// custom select
.ant-select {
  width: 300px !important;

  .ant-select-selector {
    font-size: $font-size-sm;
    // box-shadow: $theme-shadow !important;
    // border: none !important;
    border: 1px solid $primary !important;
    border-radius: 100px !important;
    height: 32px !important;
    padding: 0 18px !important;
    background: #ffffff !important;
  }

  .ant-select-arrow {
    right: 15px;
  }
}

// custom date picker
.ant-picker {
  font-size: $font-size-sm;
  // box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) !important;
  // border: none !important;
  border: 1px solid $primary;
  border-radius: 100px !important;
  padding: 0 18px 0px 0px !important;
  // box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
  background: #ffffff !important;
  width: 300px;
  height: 32px !important;

  input {
    font-size: $font-size-sm;
    box-shadow: none !important;
    height: 30px !important;
  }
}

.ant-btn-primary {
  span {
    color: $white;
  }
}

// custom date picker
.c-date-picker {
  .ant-picker {
    font-size: $font-size-sm;
    border: none !important;
    border-radius: 100px !important;
    box-shadow: $theme-shadow !important;
    background: #ffffff !important;
    width: 300px;
    height: 32px !important;
    margin: 0;
    input {
      font-size: $font-size-sm;
      font-family: $font-medium;
      box-shadow: none !important;
      height: 30px;
      padding-left: 1em;
    }
  }
  .ant-picker-lg {
    width: 300px;
  }
  .ant-picker-md {
    width: 200px;
  }
  .ant-picker-sm {
    width: 140px;
  }
  .ant-picker-xs {
    width: 100px;
  }
}

// custom phone number input
.react-tel-input {
  width: 300px;
  font-size: $font-size-md;
  // box-shadow: $theme-shadow;
  border: 1px solid $primary !important;
  border-radius: 100px;
  height: 35px;
  padding: 0 18px 0px 0px;
  background: #ffffff;

  .flag-dropdown {
    background-color: transparent;
    border: none;
    border-radius: 20px 0 0 20px;
    padding: 0 0px 0px 5px;
  }

  .form-control {
    max-width: 260px !important;
    box-shadow: none !important;
    left: 30px;
    border: none !important;
    height: 30px !important;
  }

  .selected-flag:hover {
    background: transparent;
  }

  .flag-dropdown.open {
    border-radius: 20px 0 0 20px;
    background: transparent;

    .selected-flag {
      border-radius: 20px 0 0 20px;
      background: transparent;
      width: 30px;
    }
  }
}

.c-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

// custom card container
.c-card-container {
  max-width: 900px;
  width: 100%;
  height: 720px;
  border-radius: 20px;
  box-shadow: 0 0 16px #00000026;
  padding: 0px !important;
  overflow: hidden;

  .first-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 170px;
    // background: transparent url("../../assets/images/auth/login-background.png") 0% 0% no-repeat padding-box;
    // background-position: top;
    // background-repeat: no-repeat;
    // background-size: 100% 100%;

    img {
      width: 320px;

      &.small {
        width: 70px;
      }

      &.medium {
        width: 200px;
      }
    }

    p {
      color: $white;
      font-family: $font-bold;
    }
    .login-background-image {
      width: 100%;
    }
  }

  .second-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 100% !important;
    min-height: 400px;

    &.with-pad {
      padding: 0px 100px;
    }
  }
}

// custom form
.c-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px 40px 0px;

  .form-title {
    font-family: $font-bold;
    font-size: $font-size-lg;
    margin-bottom: 28px;
  }
  .ant-radio-group {
    margin-bottom: 18px;
  }

  label {
    font-family: $font-medium;
    color: $text-color;
    display: flex;
    align-items: center;

    &.required:after {
      content: "  *";
      margin: 0px 5px 0px 3px;
      color: red;
    }
  }

  .c-label {
    display: flex;
    align-items: end;
  }

  .alt-text {
    font-size: $font-size-sm;
  }

  .ant-checkbox-wrapper {
    span {
      font-size: $font-size-sm;
    }
  }
}

// custom divider

.divider {
  border-top: 0.5px solid #bbb;
  width: 100%;
}

// custom steps
.ant-steps-item-title::after {
  height: 3px;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $green;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: $green;
  border-color: $green;

  .ant-steps-icon {
    color: $white;
    top: -2px;
  }
}

.ant-steps-item-icon {
  background-color: $green;
  border-color: $green;

  .ant-steps-icon {
    color: $white;
    top: 0px;
  }
}

.ant-steps-small {
  .ant-steps-item-title {
    padding-right: 0px;
  }

  .ant-steps-item-icon {
    margin: 0 0px 0 0;
  }

  &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0px;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: $green;
  border-color: $green;
}

.ant-form-vertical .ant-form-item {
  flex-direction: row;
}

.images-container-modal {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;

  .ant-image {
    width: calc(33.33% - 10px);
    height: 100%;
    margin: 5px 5px;

    .ant-image-img {
      height: 100%;
    }
  }
}

// custom radio buttons

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $primary;
}

.ant-radio-checked {
  .ant-radio-inner {
    border-color: $primary;

    &::after {
      background: $primary;
    }

    &:focus {
      border-color: $primary;
    }
  }

  &::after {
    border-color: $primary;
    background: $primary;
  }
}

// custom ant select icon

.ant-select .ant-select-arrow {
  right: 15px;
  width: 0;
  overflow: hidden;
  color: transparent;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #6c6c6c;
  top: 19px;
}

.ant-radio-group {
  display: flex;
  flex-direction: column;
}

.ant-radio-wrapper {
  margin: 0px 0px 10px 0px;
}

// notification-count

.c-notification-count {
  padding: 0;
  background-color: $primary;
  width: 15px;
  height: 15px;
  border-radius: 25px;
  text-align: center;
  font-size: $font-size-x-sm;
  color: white;
}

.center-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

// maps
.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

@media screen and (max-width: 540px) {
  .c-input {
    width: 100%;

    input.ant-input-sm,
    input.ant-input-lg,
    input.ant-input-medium,
    .ant-picker {
      width: 100%;
    }

    .ant-select {
      width: 100% !important;
    }
  }
  .c-date-picker {
    width: 100%;
    .ant-picker.ant-picker-lg,
    .ant-picker-md,
    .ant-picker-sm,
    .ant-picker-xs {
      width: 100%;
    }
  }
}

// @media screen and (min-width: 541px) and (max-width: 1024px) {
//   .c-input {
//     width: 100%;
//     max-width: 300px;
//     min-width: 160px;
//     margin: 10px;

//     input.ant-input-sm,
//     input.ant-input-lg,
//     input.ant-input-medium,
//     .ant-picker {
//       width: 100%;
//     }
//     .ant-select {
//       width: 100% !important;
//     }
//     // input {
//     //   &.ant-input-lg {
//     //     width: 100%;
//     //   }

//     //   &.ant-input-sm {
//     //     width: 100%;

//     //   }
//     //   &.xs {
//     //     width: 100%;

//     //   }
//     // }
//   }
// }
@media screen and (max-width: 1024px) {
  .react-tel-input .form-control {
    width: unset !important;
  }
  .react-tel-input {
    width: 100%;
    height: 32px;
  }
}
// select placeholder
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 34.4px;
}

html {
  image-rendering: -moz-crisp-edges !important; /* Firefox */
  image-rendering: -o-crisp-edges !important; /* Opera */
  image-rendering: -webkit-optimize-contrast !important; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges !important;
  -ms-interpolation-mode: nearest-neighbor !important;
}
