@import "~bootstrap/scss/bootstrap";

@import "variables";
@import "mixins";
@import "fonts";
@import "global";

@import "home";

@import "../../app-ui/index";

/* width */
::-webkit-scrollbar {
  width: 6px;
  // border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  background-color: rgba($color: grey, $alpha: 0.3);
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  // border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}
