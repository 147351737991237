@import "../../assets/scss/variables";
.emp-agncy-main {
  h1 {
    font-family: $font-subtitle;
    font-size: 36px;
    margin: 0 0 20px 0;
  }
  p {
    font-size: 24px;
    max-width: 480px;
    font-family: $font-light;
  }
  padding: 40px 0;
  .c-containers {
    padding: 0 88px;
    .c-row {
      display: flex;
      .cell-30 {
        width: 40%;
      }
      .cell-auto {
        width: 60%;
      }
      .cell-full {
        width: 100%;
      }
      .text-section {
        display: flex;
        justify-content: center;
      }
      .image-section {
        img {
          width: 100%;
        }
      }
      .center {
        justify-content: center;
      }
      .align-left {
        justify-content: flex-start;
      }
      .align-right {
        justify-content: flex-end;
      }
    }
  }
  .emp-heading {
    font-size: 40px;
    line-height: 55px;
  }
  .blue {
    color: $blue;
  }
  .job-n-demo {
    display: flex;
    justify-content: center;
  }
  .sections {
    padding-top: 200px;
    // h1 {
    //   font-size: $font-size-30;
    //   margin: 0 0 28px 0;
    // }
    // p {
    //   font-size: $font-size-lg;
    //   max-width: 460px;
    // }
  }
}
