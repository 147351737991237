.c-banner-with-images {
  width: 100%;


  .imgs-row {
    display: flex;

    .banner-img-main {

      position: relative;
      width: 33.33%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      overflow: hidden;

      .second {
        width: 100%;

        img {
          width: 100%;
        }
      }


      .first {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: contain;
        z-index: 10;
        top: 0;
        left: 0;
        opacity: 0;
        // transition: .1s linear;
        transition: all ease 0.4s;


        img {
          width: 100%;
          pointer-events: none;
        }

      }



      >div {
        width: 32%;
      }
    }

    .banner-img-main:hover .first {
      opacity: 1;
    }
  }
}