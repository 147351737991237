.c-job-card {
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin: 0px 0px 15px 0px;
  justify-content: space-between;
  overflow: hidden;
  padding: 10px 10px 10px 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) !important;
  transition: 0.3s all linear;
  cursor: pointer;

  p {
    margin: 0px;
    font-size: $font-size-x-sm;
  }

  .job-card-first-container {
    display: flex;
    width: 100%;
    align-items: flex-end;

    .job-thumbnail {
      max-width: 85px;

      max-height: 85px;
      border-radius: 5px;
      margin: 0px 12px 0px 0px;
      padding: 5px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
    }

    .info-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      border-bottom: $border-color 1px solid;
      padding: 0px 0px 5px 0px;
      margin: 20px 0px 0px 0px;

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 60px;

        .title-row {
          display: flex;
          align-items: center;

          .title {
            font-size: $font-size-md;
            font-family: $font-subtitle;
            margin: 0px;
          }

          .location-container {
            display: flex;
            margin: 0px 0px 0px 10px;

            .location-icon {
              max-width: 10px;
            }

            .location-text {
              margin: 0px 0px 0px 5px;
              font-size: $font-size-sm;
            }
          }
        }

        p,
        a {
          font-family: $font-light;
          margin-bottom: 2px;
        }
      }
    }

    .more-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      align-self: flex-end;
      p,
      a {
        // font-family: $font-light !important;
        margin-bottom: 2px;
        white-space: break-all;
      }
    }
  }
  .details-container {
    display: flex;
    flex: 1;
    width: 100%;
    height: 30px !important;
    min-height: 20px;
    overflow: hidden;
    padding: 10px 10px 0 10px;

    p {
      text-align: left;
    }
  }

  .details-container-box {
    // display: flex;
    // flex: 1;
    width: 100%;
    padding: 10px 0;

    p {
      text-align: left;
    }
    .job-title {
      font-family: $font-subtitle !important;
    }
  }

  .tag-container {
    position: absolute;
    top: 0;
    display: flex;
    right: 0;
    align-items: flex-start;

    .jobcard-checkbox-mobile {
      display: none !important;
    }
    .featured-tag {
      position: relative;
      top: -6px;
      right: 0px;
      height: 45px;
      width: 99px;
      background: url("../../assets/images/icons/job-tag-back-icon.png");
      background-size: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 5px 7px 0px;

      .tag-icon {
        width: 15px;
        margin-right: 3px;
      }

      .tag-text {
        color: $white;
        font-size: 9px;
        font-family: $font-medium !important;
      }
    }

    .job-tag {
      display: flex;
      align-items: center;
      padding: 6px 5px;
      margin: 0px 5px 0px 0px;

      .tag-icon {
        width: 18px;
        margin-right: 3px;
      }

      .tag-text {
        color: $text-color;
        font-size: 10px;
        font-family: $font-medium !important;
      }
    }
  }

  &:hover {
    box-shadow: rgba($color: $primary, $alpha: 0.5) 2px 2px 9px !important;
  }

  &.active {
    box-shadow: rgba($color: $primary, $alpha: 0.5) 2px 2px 9px !important;
  }

  &.box {
    height: 200px;
    width: 200px;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 15px 5px 15px;

    .info-wrapper {
      flex-direction: column;

      .job-thumbnail {
        max-width: 80px;
        max-height: 80px;
      }

      .info {
        flex-direction: column;
        justify-content: space-around;
        height: 90px;
        margin: 10px 0px 0px 0px;

        .title-row {
          flex-direction: column;

          .location-container {
            margin: 5px 0px 0px 0px;
          }
        }
      }
    }

    .more-info {
      align-self: flex-end;
      position: absolute;
      bottom: 5px;
      right: 10px;
    }
  }
  &.selected {
    border: 1.2px solid $primary;
  }
  .c-job-card {
    width: 100%;
    border-radius: 10px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin: 0px 0px 15px 0px;
    justify-content: space-between;
    overflow: hidden;
    padding: 10px 10px 10px 10px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) !important;
    transition: 0.3s all linear;
    cursor: pointer;

    p {
      margin: 0px;
      font-size: $font-size-x-sm;
    }

    .job-card-first-container {
      display: flex;
      width: 100%;
      align-items: flex-end;

      .job-thumbnail {
        max-width: 85px;

        max-height: 85px;
        border-radius: 5px;
        margin: 0px 12px 0px 0px;
        padding: 5px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      }

      .info-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        border-bottom: $border-color 1px solid;
        padding: 0px 0px 5px 0px;
        margin: 20px 0px 0px 0px;

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          height: 60px;

          .title-row {
            display: flex;
            align-items: center;

            .title {
              font-size: $font-size-md;
              font-family: $font-subtitle;
              margin: 0px;
            }

            .location-container {
              display: flex;
              margin: 0px 0px 0px 10px;

              .location-icon {
                max-width: 10px;
              }

              .location-text {
                margin: 0px 0px 0px 5px;
                font-size: $font-size-sm;
              }
            }
          }

          p,
          a {
            font-family: $font-light;
            margin-bottom: 2px;
          }
        }
      }

      .more-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        align-self: flex-end;
        p,
        a {
          // font-family: $font-light !important;
          margin-bottom: 2px;
          white-space: break-all;
        }
      }
    }
    .details-container {
      display: flex;
      flex: 1;
      width: 100%;
      height: 30px !important;
      min-height: 20px;
      overflow: hidden;
      padding: 10px 10px 0 10px;

      p {
        text-align: left;
      }
    }

    .details-container-box {
      // display: flex;
      // flex: 1;
      width: 100%;
      padding: 10px 0;

      p {
        text-align: left;
      }
      .job-title {
        font-family: $font-subtitle !important;
      }
    }

    .tag-container {
      position: absolute;
      top: 0;
      display: flex;
      right: 0;
      align-items: flex-start;

      .jobcard-checkbox-mobile {
        display: none !important;
      }
      .featured-tag {
        position: relative;
        top: -6px;
        right: 0px;
        height: 45px;
        width: 99px;
        background: url("../../assets/images/icons/job-tag-back-icon.png");
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 5px 7px 0px;

        .tag-icon {
          width: 15px;
          margin-right: 3px;
        }

        .tag-text {
          color: $white;
          font-size: 9px;
          font-family: $font-medium !important;
        }
      }

      .job-tag {
        display: flex;
        align-items: center;
        padding: 6px 5px;
        margin: 0px 5px 0px 0px;

        .tag-icon {
          width: 18px;
          margin-right: 3px;
        }

        .tag-text {
          color: $text-color;
          font-size: 10px;
          font-family: $font-medium !important;
        }
      }
    }

    &:hover {
      box-shadow: rgba($color: $primary, $alpha: 0.5) 2px 2px 9px !important;
    }

    &.active {
      box-shadow: rgba($color: $primary, $alpha: 0.5) 2px 2px 9px !important;
    }

    &.box {
      height: 200px;
      width: 200px;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px 15px 5px 15px;

      .info-wrapper {
        flex-direction: column;

        .job-thumbnail {
          max-width: 80px;
          max-height: 80px;
        }

        .info {
          flex-direction: column;
          justify-content: space-around;
          height: 90px;
          margin: 10px 0px 0px 0px;

          .title-row {
            flex-direction: column;

            .location-container {
              margin: 5px 0px 0px 0px;
            }
          }
        }
      }

      .more-info {
        align-self: flex-end;
        position: absolute;
        bottom: 5px;
        right: 10px;
      }
    }
    &.selected {
      border: 1.2px solid $primary;
    }
  }
}
