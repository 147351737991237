.c-header {
  position: sticky;
  top: 0px;
  height: 60px;
  z-index: 100;
  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px 0px 50px;
    height: 64px;
    background-color: $white;
  }
  nav {
    display: flex;
    margin: 0px;
    &.menu {
      li {
        color: $text-color;
      }
    }
    a {
      list-style: none;
      padding: 0px 12px;
      // font-weight: 400;
      color: $secondary;
      &.active {
        color: $primary;
      }
      &.sign-up {
        color: $primary;
        // font-weight: bold;
      }
      &.login {
        color: $secondary;
        // font-weight: bold;
      }
    }
  }
  .logo {
    width: 230px;
  }
  .shadow-box {
    background: transparent radial-gradient(closest-side at 50% 75%, #9b9b8f -81%, #ffffff00 100%) 0% 0% no-repeat
      padding-box;
    height: 80px;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
  }
  .sign-up-btn {
    font-family: $font-bold;
  }
}
