@media screen and (max-width: 1200px) {
  .c-header {
    .close-icon {
      transition: 0.5s ease;
      animation: mymove both 0.3s ease;
      transform: rotate(95deg);
    }

    .menu-icon {
      transition: 0.5s ease;
      animation: menuMove both 0.5s ease;
    }

    @keyframes menuMove {
      from {
        opacity: 0;
        transform: translateX(100%);
      }

      to {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @keyframes mymove {
      from {
        transform: rotate(95deg);
      }

      to {
        transform: rotate(0deg);
      }
    }

    .menu {
      display: none;
    }

    nav a.login {
      display: none;
    }

    .mobile-menu {
      position: fixed;
      left: 0;
      top: 64px;
      background-color: #ffffff;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      transform: translateX(-100%);
      opacity: 0;
      transition: 0.3s ease-in;
      overflow-y: auto;
      z-index: 999;

      a {
        margin: 0 0 0 50px;
        list-style: none;
        font-family: $font-bold;
        color: $text-color;
        font-size: 24px;
        padding: 15px 0;
      }
    }

    .menu-open {
      transform: translateX(0);
      opacity: 1;
    }

    .links-wrapper {
      width: 100%;
      height: 100%;
      min-height: 550px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }
  }
}

@media screen and (max-width: 480px) {
  .c-header {
    .inner-container {
      padding: 0 20px 0 20px;
    }

    .logo {
      max-width: 150px;
      width: 100%;
    }

    .mobile-menu a {
      margin: 0 0 0 20px;
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .c-header {
    .toggle-button {
      display: none;
    }

    .mobile-menu {
      display: none;
    }
  }
}
