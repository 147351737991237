.c-images-gallery{
    display: flex;
    flex-direction: column;
    width: 100%;

    .c-images-gallery-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;

        .title{
            font-size: $font-size-md; 
            margin: 0px;
            
            &:hover{
                text-decoration: underline;
                color: $text-color;
            }

        }

    }

    .images-container{
        display: flex;
        justify-content: space-between;
        max-height: 200px;

        .ant-image{
            width: calc(33.33% - 10px);
            height: 100%;
            
            .ant-image-img{
                height: 100%;    
            }
 
        }

    }

    
   

}  