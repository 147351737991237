@media only screen and (max-width: 991px) {
    .c-footer{
        .inner-container{
            padding: 2em;
            .info{
                flex-basis: 100%;
                margin: 0 0 0 0 !important;
            }
            ul{
                flex: 4;
            }
        }
    }



}

@media only screen and (max-width: 768px) {
    .c-footer{
        .inner-container{
            padding: 2em;
            .info{
                flex-basis: 100%;
            }
            ul{
                flex: 4;
            }
        }
    }



}

@media only screen and (max-width: 480px) {
    .c-footer{
        .inner-container{
            padding: 1em 8px;
            .info{
                flex-basis: 100%;
                margin: 0 !important;
            }
            ul{
                // flex-basis: 100%;
                margin-right: 0;
                // text-align: center;
                .logo{
                    margin-bottom: 4px;
                }
            }
        }
    }



}