@import "../../assets/scss/variables";
.services-main {
  display: flex;

  h1 {
    font-family: $font-subtitle;
    font-size: 36px;
    margin: 0 0 20px 0;
  }
  p {
    font-size: 24px;
    font-family: $font-light;
  }
  .cust-reward-section {
    width: 60%;
    height: 100%;
    min-height: fit-content;
    padding: 100px 88px;

    .coming-soon {
      color: $blue;
      font-size: $font-size-x-lg;
      text-transform: uppercase;
    }
    .services-desc {
      font-size: $font-size-30;
      margin: 20px 0;
    }
    .full-desc {
      font-size: $font-size-lg;
      max-width: 800px;
    }
    .icons {
      display: flex;
      flex-wrap: wrap;
      margin: -10px 0 0 0;

      img {
        margin: 40px 50px 0 0;
        max-width: 70px;
      }
    }
  }
  .user-services {
    width: 40%;
    background-image: url("../../assets/images/Services/form-bg.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;

    .services-form {
      background-color: rgba(255, 255, 255, 0.805);
      border-radius: 10px;
      padding: 30px;

      .services-desc {
        font-size: $font-size-lg;
        line-height: 35px;
        font-family: $font-medium;
      }

      // p {
      //   font-size: $font-size-lg;
      // }
      .services-form-wrapper {
        width: unset;
        display: unset;
        flex-direction: unset;

        .text-area {
          .ant-input {
            border: 1px solid $primary;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            border-radius: 20px;
            resize: none;
          }
          .ant-input:focus,
          .ant-input-focused {
            border-right-width: unset !important;
            box-shadow: unset;
          }
        }
        .submit-btn {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
